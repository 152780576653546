import React from 'react';
import './styles8001.css';

function BSTVisualization() {
    return (
        <iframe 
            className="bst" 
            id="AbouelnileFrame" 
            src="https://www.youtube.com/embed/oxug3DH1y1o" 
            title="AbouelNileMotors Video" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen
        />
    );
}

export default BSTVisualization;
